/* globalStyles.css */

/* Hide scrollbars for WebKit browsers */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  box-sizing: border-box;
}

@font-face {
  font-family: 'BRHendrix';
  src: url('./assets/fonts/BRHendrix-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BRHendrix';
  src: url('./assets/fonts/BRHendrix-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BRHendrix';
  src: url('./assets/fonts/BRHendrix-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'BRHendrix';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'BRHendrix', monospace;
}

.grecaptcha-badge {
  visibility: hidden;
  display: none;
}